import { ThemeProvider, theme, Box } from "@chakra-ui/react";
import Footer from "./footer";
import Header from "./header";
import React, { useState, useEffect } from "react";
import { GetCookie } from "../provider/common";
import VOC from "../components/board/voc";
import PopUp from "./popUp";

export default function IndexLayout({ children }) {
  const [showBox, setShowBox] = useState(false);
  const [isLogin, setIsLogin] = useState(0);

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    const loginInfo = await GetCookie("isLogin");
    setIsLogin(loginInfo);
  };

  const openModal = () => {
    setShowBox(true);
  };
  const closeModal = () => {
    setShowBox(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLogin === "1" ? (
          <>
            <div className="scroll_container">
              <button id="saleBtn" onClick={openModal}>
                <i></i>
              </button>
            </div>
            <VOC showBox={showBox} closeModal={closeModal} />
          </>
        ) : (
          ""
        )}

        <Header />
        <PopUp />
        <Box>
          <main>{children}</main>
        </Box>
        <Footer />
      </ThemeProvider>
    </>
  );
}
